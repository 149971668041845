$fa-font-path: "../webfonts";

$font-primary: "Roboto", sans-serif;
$font-secondary: "Rubik", sans-serif;

$color-primary: #0062ae;
$color-primary-dark: #1c6469;
$color-secondary: #0062ae;
$color-secondary-dark: #151c52;

$pretty--color-default: #ced4da;
$pretty--color-primary: #0062ae;
$pretty--color-primary-dark: #004c87;

:root {
    --animate-duration: 500ms;
    --animate-delay: 0.2s;
}

.btn-info{
    background-color: $pretty--color-primary !important;
    border-color: $pretty--color-primary !important;
}
