#calendar-wrapper {
    ::-webkit-file-upload-button {
        display: none;
    }

    .event-type-event {
        .fc-event-main {
            color: #000 !important;
        }
    }

    .prilozeni-souboru {
        width: 300px !important;
        color: #54667a !important;
        background-color: #ebf3f5 !important;
        border: 2px solid #ebf3f5 !important;
        border-radius: 4px;
        padding: 6px 12px;
        cursor: pointer;
    }

    .table td,
    .table th {
        padding: 10px 5px;
    }

    .fc-header-toolbar {
        padding: 0;
    }

    .fc-list-item.bg-primary {
        color: silver;
    }

    .bg-success2 {
        background-color: green;
    }

    .fc-event {
        box-sizing: border-box;
        border: 2px solid #000;
        border-radius: 0.5rem;
        transition: opacity 0.2s ease-in-out;
    }

    .fc-list-event {
        border: none;
        background-color: #fff;
        color: #000 !important;
    }

    .fc-list-event-dot {
        box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
    }

    .fc-timegrid-slot-label {
        color: #a5b2c1;
    }

    .fc-list-event-graphic {
        padding-right: 14px;
        background-color: #f5f5f5;
    }

    .fc-toolbar .fc-button-group {
        border: none;
    }

    .fc-event:hover {
        opacity: 0.8;
    }

    .fc .fc-button-primary {
        background-color: #0062ae;
        border-color: #0062ae;
    }

    .fc .fc-button-primary:not(:disabled).fc-button-active,
    .fc .fc-button-primary:not(:disabled):active {
        background-color: $pretty--color-primary-dark;
        border-color: $pretty--color-primary-dark;
    }

    .fc .fc-button-primary:hover {
        background-color: $pretty--color-primary-dark;
        border-color: $pretty--color-primary-dark;
    }

    .fc-daygrid-dot-event .fc-event-title {
        font-weight: 700;
    }

    .fc .fc-list-event:hover td {
        background-color: #d8e6e8 !important;
    }

    .fc-timegrid-event-short .fc-event-time::after {
        content: none !important;
    }

    .calendar-popover {
        max-width: 200px;
    }

    .calendar-event-popover {
        max-width: 350px;
    }

    @include media-breakpoint-down(md) {
        .fc-button {
            font-size: 15px;
        }

        .fc .fc-toolbar-title {
            font-size: 15px;
        }

        .fc-toolbar-chunk {
            font-size: 15px;
        }

        .fc-toolbar-title {
            font-size: 15px;
        }
    }

    .fc-icon-fa {
        font-family: "Font Awesome 6 Free" !important;
        font-weight: 900;
    }

    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0 2.5%;
    }
}
